const colors = {
  transparent: 'transparent', // 0
  black: '#000000', // 1
  white: '#ffffff', // 2
  headingColor: '#2a313c',
  textColor: '#5d646d', // 3
  labelColor: '#767676', // 4
  inactiveField: '#f2f2f2', // 5
  inactiveButton: '#b7dbdd', // 6
  inactiveIcon: '#EBEBEB', // 7
  primary: '#2860f9', // 8
  primaryHover: '#2860f9', // 9
  secondary: '#ff5b60', // 10
  secondaryHover: '#FF282F', // 11
  yellow: '#fdb32a', // 12
  yellowHover: '#F29E02', // 13
  primaryBoxShadow: ' rgba(26, 115, 232, 0.57) 0px 9px 20px -5px ',
  third: '#52bd95',
  thirdHover: '#399c78',
};

export default colors;
