import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Icon from 'react-icons-kit';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { ic_signal_wifi_4_bar } from 'react-icons-kit/md/ic_signal_wifi_4_bar';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList({ fullMenu }) {
  const classes = useStyles();
  const [open, setOpen] = useState(null);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {fullMenu.map((mainItem, i) => (
        <Fragment key={`main-${i}-${mainItem.label}`}>
          {i !== 0 && <Divider component="li" />}

          <ListItem
            onClick={() => {
              if (mainItem.path) {
                window.open(mainItem.path, '_self');
              } else if (mainItem.label === open) {
                setOpen(null);
              } else {
                setOpen(mainItem.label);
              }
            }}
          >
            <ListItemText primary={mainItem.label} />
            {mainItem.options && mainItem.options.length ? (
              open === mainItem.label ? (
                <Icon icon={ic_keyboard_arrow_up} />
              ) : (
                <Icon icon={ic_keyboard_arrow_down} />
              )
            ) : (
              ''
            )}
          </ListItem>

          {mainItem.options && mainItem.options.length ? (
            <Collapse in={open === mainItem.label} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {mainItem.options.map((option, j) => (
                  <Fragment
                    key={`nested-1-${j}-${option.label ||
                      option.options.length} `}
                  >
                    {option.label ? (
                      <ListItem
                        className={classes.nested}
                        onClick={() => {
                          window.open(option.path, '_self');
                        }}
                      >
                        <ListItemText primary={option.label} />
                      </ListItem>
                    ) : (
                      option.options.map((op, k) => (
                        <ListItem
                          key={`nested-1-${k}-${op.name}`}
                          className={classes.nested}
                          onClick={() => {
                            window.open(op.link, '_self');
                          }}
                        >
                          <ListItemText primary={op.name} />
                        </ListItem>
                      ))
                    )}
                  </Fragment>
                ))}
              </List>
            </Collapse>
          ) : (
            ''
          )}
        </Fragment>
      ))}
    </List>
  );
}
