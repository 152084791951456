import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { DrawerContext } from '../../contexts/DrawerContext';
import Tooltip from '@material-ui/core/Tooltip';
import {
  OlStyled,
  tooltipProps,
  HeaderWrapper,
  MainLi,
  MainUlMenu,
} from './scollSpyMenu.style';
import { user } from 'react-icons-kit/icomoon/user';
import { target } from 'react-icons-kit/icomoon/target';
import { play } from 'react-icons-kit/icomoon/play';
import Icon from 'react-icons-kit';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import MiniMenu from './miniMenuSmall';
import MenuPopper from './menuPopper';
import NestedList from './newDrawerMenu';

const useStyles = makeStyles(theme => ({
  tooltipArrow: {
    fontSize: '1.5rem',
    color: 'white',
  },
  listItemMenu: {
    cursor: 'pointer',
  },
  highlight: {
    '&::after': {
      width: '100% !important',
      left: '0 !important',
    },
  },
}));

const ScrollSpyMenu = ({
  smallMenu = false,
  className,
  menuItems,
  drawerClose,
  ...props
}) => {
  const { dispatch } = useContext(DrawerContext);
  const [path, setPath] = useState(null);
  const [popper, setPopper] = useState({
    anchorEl: null,
    menu: [],
    hasTwoBlocks: false,
    width: '50rem',
    open: false,
  });

  const classes = useStyles();

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  useEffect(() => {
    setPath(window.location.pathname.toString());
  }, []);
  if (smallMenu) {
    return <NestedList fullMenu={menuItems} />;
  }
  return (
    <>
      <Scrollspy
        items={[]}
        className={addAllClasses.join(' ')}
        drawerClose={drawerClose}
        {...props}
      >
        {menuItems.map((menu, index) => {
          return (
            <MainUlMenu key={`menu-item-${index}-${menu.title}`}>
              <a
                onClick={() => {
                  if (menu.path) {
                    window.open(menu.path, '_self');
                  }
                }}
                onMouseLeave={() => {
                  setPopper({
                    ...popper,
                    open: false,
                  });
                }}
                onMouseEnter={event => {
                  if (menu.options.length) {
                    setPopper({
                      anchorEl: event.currentTarget,
                      menu: menu.options,
                      hasTwoBlocks: menu.hasTwoBlocks,
                      width: menu.width,
                      open: true,
                      index,
                    });
                  }
                }}
                className={
                  popper.index === index && popper.open ? classes.highlight : ''
                }
                style={menu.path ? {} : { cursor: 'default' }}
              >
                {menu.label}
              </a>
            </MainUlMenu>
          );
        })}
        <MenuPopper setPopper={setPopper} popper={popper} />
      </Scrollspy>
    </>
  );
};

ScrollSpyMenu.defaultProps = {
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
