import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import NavbarWrapper from 'common/src/components/Navbar';
import Drawer from 'common/src/components/Drawer';
import Logo from 'common/src/components/UIElements/Logo';
import Box from 'common/src/components/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Button from 'common/src/components/Button';
import styled from 'styled-components';
import LogoImage from 'common/src/assets/image/saas/white_logo_cropped.png';

const MenuWrapper = styled.div`
  align-self: center;
  margin-left: 5rem;
  @media (max-width: 991px) {
    position: absolute;
    right: 0;
    margin-right: 20px;
  }
`;

const Navbar = ({ navbarStyle, logoStyle, row, containedStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MENU_ITEMS {
          label
          path
          offset
          staticLink
          hasTwoBlocks
          width
          options {
            left
            title
            label
            path
            options {
              fullPath
              icon
              name
              secondarytext
              link
            }
          }
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
  }, []);

  function handleOpenApp() {
    window.open('https://app.salesocean.io');
  }

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Agency"
            logoStyle={(logoStyle, { style: { maxWidth: '260px' } })}
          />
          <MenuWrapper>
            <Box
              flexBox={true}
              alignItems="center"
              style={{ alignItems: 'center' }}
            >
              <ScrollSpyMenu
                className="main_menu"
                menuItems={Data.saasJson.MENU_ITEMS}
                offset={-70}
              />
              <Drawer
                width="420px"
                placement="right"
                drawerHandler={<HamburgMenu barColor="#2962ff" />}
                open={state.isOpen}
                toggleHandler={toggleHandler}
              >
                <ScrollSpyMenu
                  smallMenu={true}
                  className="mobile_menu"
                  menuItems={Data.saasJson.MENU_ITEMS}
                  drawerClose={true}
                  offset={-100}
                />
              </Drawer>
            </Box>
          </MenuWrapper>
          {width > 430 ? (
            <Button
              onClick={handleOpenApp}
              className="outlined"
              title="SIGN IN"
              variant="outlined"
              {...containedStyle}
            />
          ) : (
            ''
          )}
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  buttonWrapper: PropTypes.object,
  secondRow: PropTypes.object,
  containedStyle: PropTypes.object,
};

Navbar.defaultProps = {
  containedStyle: {
    size: 'small',
    minHeight: 'unset',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
    style: {
      position: 'absolute',
      right: 0,
      marginRight: '100px',
      top: '33%',
    },
  },
  navbarStyle: {
    className: 'hosting_navbar',
    minHeight: '70px',
    display: 'block',
  },
  secondRow: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    width: '20%',
    style: {
      verticalAlign: 'text-bottom',
    },
  },
  row: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'flex-start',
    ],
    width: '90%',
  },
  logoStyle: {
    maxWidth: '130px',
    mr: [0, 0, 0, '166px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  buttonWrapper: {
    flexBox: true,
    alignItems: 'end',
  },
};

export default Navbar;
