import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TempImg from 'common/src/assets/image/saas/rocket_features.svg';
import { HoverDiv } from './scollSpyMenu.style';
import LayerIcon from 'common/src/assets/image/saas/layers.svg';
import AutomateIcon from 'common/src/assets/image/saas/automate.svg';
import AnalyseIcon from 'common/src/assets/image/saas/analyse.svg';
import ShareIcon from 'common/src/assets/image/saas/share.svg';
import TargetIcon from 'common/src/assets/image/saas/target.svg';
import StartupIcon from 'common/src/assets/image/saas/startup.svg';
import TimeIcon from 'common/src/assets/image/saas/manager_icon.svg';
import OrganizeIcon from 'common/src/assets/image/saas/ae_icon.svg';
import TelephoneIcon from 'common/src/assets/image/saas/bdr_icon.svg';
import TechIcon from 'common/src/assets/image/saas/tech.svg';
import SaasIcon from 'common/src/assets/image/saas/tech_icon_blue.svg';
import LeadGenIcon from 'common/src/assets/image/saas/leadGen.svg';
import BlogIcon from 'common/src/assets/image/saas/blog_icon.svg';
import ContactIcon from 'common/src/assets/image/saas/contact_icon.svg';
import SecurityIcon from 'common/src/assets/image/saas/security_icon.svg';
import QuestionIcon from 'common/src/assets/image/saas/questions.svg';
import IntegrationIcon from 'common/src/assets/image/saas/integration_icon.svg';
import SupportIcon from 'common/src/assets/image/saas/support_icon.svg';
import LeadGeneration from 'common/src/assets/image/saas/lead_gen_icon_blue.svg';
import CareerIcon from 'common/src/assets/image/saas/career.svg';
const icons = {
  layers: LayerIcon,
  default: TempImg,
  automate: AutomateIcon,
  analyse: AnalyseIcon,
  share: ShareIcon,
  question: QuestionIcon,
  startup: StartupIcon,
  target: TargetIcon,
  leadGen: LeadGeneration,
  tech: TechIcon,
  bdr: TelephoneIcon,
  manager: TimeIcon,
  ae: OrganizeIcon,
  saas: SaasIcon,
  blog: BlogIcon,
  security: SecurityIcon,
  integration: IntegrationIcon,
  support: SupportIcon,
  contact: ContactIcon,
  abm: LeadGenIcon,
  career: CareerIcon
};

const useStyles = makeStyles({
  root: {
    padding: '0 1rem 1rem 1rem'
  },
  imageDiv: {
    display: 'inline-block',
    width: '15%',
    display: 'inline-flex',
    justifyContent: 'center'
  },
  textDiv: {
    paddingLeft: '5px',
    display: 'inline-block',
    width: '85%'
  },
  hightLighted: {
    fontWeight: 800,
    margin: 0
  },
  secondaryText: {
    fontSize: '14px',
    margin: 0
  },
  title: {
    marginBottom: 0
  },
  img: {
    width: '2.1rem'
  }
});

const LeftBlock = ({ title, options }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <p className={classes.title}>{title}</p>
      </Box>
      {options.map((option, i) => (
        <HoverDiv key={`hover-item-${i}-${option.name}`} href={option.link}>
          <div className={classes.imageDiv}>
            <img
              src={icons[option.icon ? option.icon : 'default']}
              className={classes.img}
            />
          </div>
          <div className={classes.textDiv}>
            <p className={classes.hightLighted}>{option.name}</p>
            <p className={classes.secondaryText}>{option.secondarytext}</p>
          </div>
        </HoverDiv>
      ))}
    </Box>
  );
};

export default LeftBlock;
