import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Logo from 'common/src/components/UIElements/Logo';
import Container from 'common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  BottomText,
  BottomDiv,
} from './footer.style';
import { twitter } from 'react-icons-kit/icomoon/twitter';
import { linkedin } from 'react-icons-kit/icomoon/linkedin';
import mediumLogo from 'common/src/assets/image/saas/medium_logo.svg';
import { Icon } from 'react-icons-kit';
import LogoImage from 'common/src/assets/image/saas/logo.png';
import { Cookies } from 'react-cookie-consent';

const iconStyle = {
  display: 'inline-block',
  width: '25px',
  height: '25px',
  margin: '10px',
  cursor: 'pointer',
};

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  function handleRemoveCookies() {
    Cookies.remove('google_analytics');
    Cookies.remove('google_analytics-legacy');
    Cookies.remove('view_demo_videos_cfsnjs');
    window.alert('Successfully deleted Cookies');
  }

  function handleOpenPrivacyPolicy() {
    window.open('/privacy');
  }

  function handleOpenTerms() {
    window.open('/termsAndConditions');
  }

  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        Footer_Data {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="Hosting"
              logoStyle={logoStyle}
            />
            <Text
              content={
                <a
                  style={{ color: 'inherit' }}
                  href="mailto:hello@salesocean.io"
                >
                  hello@salesocean.io
                </a>
              }
              {...textStyle}
            />

            <Icon
              icon={linkedin}
              size="100%"
              style={{
                color: '#0e76a8',
                ...iconStyle,
              }}
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/company/salesoceanbv',
                  '_blank'
                )
              }
            />
            <Icon
              icon={twitter}
              size="100%"
              style={{
                color: '#38A1F3',
                ...iconStyle,
              }}
              onClick={() =>
                window.open('https://twitter.com/SalesOcean1', '_blank')
              }
            />
            <img
              src={mediumLogo}
              style={{
                verticalAlign: 'bottom',
                ...iconStyle,
              }}
              onClick={() =>
                window.open('https://medium.com/@salesocean', '_blank')
              }
              alt="medium logo"
            />
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.saasJson.Footer_Data.map((widget, index) => (
              <Box className="col" {...col} key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <a href={item.url} className="ListItem">
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <BottomDiv>
          <Text
            content={
              <>
                <p style={{ marginBottom: 0 }}>
                  © 2020 Sales Software Solutions B.V.{' '}
                </p>{' '}
                <p style={{ marginTop: 0, marginBottom: 0 }}>CoC: 78541816</p>{' '}
              </>
            }
            {...textStyle}
            //style={{ position: 'absolute', bottom: 0, left: 0 }}
          />
        </BottomDiv>
        <BottomDiv>
          <Text
            content={
              <BottomText>
                <a
                  style={{ padding: '0 1.5rem' }}
                  onClick={handleRemoveCookies}
                >
                  Remove cookies
                </a>
                -
                <a
                  style={{ padding: '0 1.5rem' }}
                  onClick={handleOpenPrivacyPolicy}
                >
                  Privacy policy
                </a>
                -
                <a style={{ padding: '0 1.5rem' }} onClick={handleOpenTerms}>
                  Terms And Conditions
                </a>
              </BottomText>
            }
            {...textStyle}
            //style={{ position: 'absolute', bottom: 0, right: 0 }}
          />
        </BottomDiv>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Footer col one style
  colOne: {
    width: [1, '35%', '35%', '23%'],
    mt: [0, '13px'],
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: ['15px', '15px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '65%', '65%', '77%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', '50%', '50%', '25%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
  // widget title default style
  titleStyle: {
    color: '#343d48',
    fontSize: '16px',
    fontWeight: '700',
    mb: '30px',
  },
  // Default logo size
  logoStyle: {
    width: '170px',
    ml: '-8px',
  },
  // widget text default style
  textStyle: {
    color: '#2a313c',
    fontSize: '16px',
    mb: '10px',
  },
};

export default Footer;
