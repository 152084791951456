import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TempImg from 'common/src/assets/image/saas/rocket_features.svg';
import { HoverDivBlockRight } from './scollSpyMenu.style';
import AbmIcon from 'common/src/assets/image/saas/abm.svg';
import TargetIcon from 'common/src/assets/image/saas/target_persona.svg';
import FilterIcon from 'common/src/assets/image/saas/filter.svg';
import ReportIcon from 'common/src/assets/image/saas/report.svg';
import TeamworkIcon from 'common/src/assets/image/saas/teamwork.svg';
import AutomationIcon from 'common/src/assets/image/saas/automation.svg';

const icons = {
  abm: AbmIcon,
  teamwork: TeamworkIcon,
  report: ReportIcon,
  standardize: FilterIcon,
  target: TargetIcon,
  automation: AutomationIcon
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    padding: '0 1rem 1rem 1rem'
  },

  imageDiv: {
    display: 'inline-block',
    width: '15%',
    display: 'inline-flex',
    justifyContent: 'center'
  },
  textDiv: {
    paddingLeft: '5px',
    display: 'inline-block',
    width: '85%'
  },
  hightLighted: {
    fontWeight: 800,
    margin: 0
  },
  secondaryText: {
    fontSize: '14px',
    margin: 0
  },
  title: {
    marginBottom: 0
  },
  img: {
    width: '2.1rem'
  }
});

const RightBlock = ({ title, options }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <p className={classes.title}>{title}</p>
      </Box>

      {options.map((option, i) => (
        <HoverDivBlockRight
          style={i === 0 ? { marginTop: '20px' } : {}}
          key={`hover-item-${i}-${option.name}`}
          href={option.link}
        >
          <div className={classes.imageDiv}>
            <img
              src={icons[option.icon ? option.icon : 'default']}
              className={classes.img}
            />
          </div>
          <div className={classes.textDiv}>
            <p className={classes.hightLighted}>{option.name}</p>
            <p className={classes.secondaryText}>{option.secondarytext}</p>
          </div>
        </HoverDivBlockRight>
      ))}
    </Box>
  );
};

export default RightBlock;
