import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import LeftBlock from './leftBlock';
import Grid from '@material-ui/core/Grid';
import RightBlock from './rightBlock';

const useStyles = makeStyles({
  root: {},
  paperRoot: {
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px'
  },
  rightGrid: {
    background: '#F5F5F5',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  }
});

const MenuPopper = ({ setPopper, popper }) => {
  var {
    open,
    anchorEl,
    menu = [],
    hasTwoBlocks = false,
    width = '50rem'
  } = popper;
  const classes = useStyles();

  return (
    <Popper
      interActive={true}
      keepMounted={true}
      open={open}
      anchorEl={anchorEl}
      placement="bottom"
      transition
      style={{
        zIndex: 1000000
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div
            className={classes.paperRoot}
            style={{ width }}
            onMouseLeave={() => {
              setPopper({ ...popper, open: false, hoverPopper: false });
            }}
            onMouseEnter={() => {
              setPopper({ ...popper, open: true, hoverPopper: true });
            }}
          >
            {hasTwoBlocks ? (
              <Grid container>
                <Grid item lg={7}>
                  {menu.map((me, i) => {
                    if (!me.left) return '';
                    return (
                      <LeftBlock
                        title={me.title}
                        options={me.options}
                        index={i}
                      />
                    );
                  })}
                </Grid>
                <Grid item lg={5} className={classes.rightGrid}>
                  {menu.map(me => {
                    if (me.left) return '';
                    return <RightBlock title={me.title} options={me.options} />;
                  })}
                </Grid>
              </Grid>
            ) : (
              menu.map(me => {
                return <LeftBlock title={me.title} options={me.options} />;
              })
            )}
          </div>
        </Fade>
      )}
    </Popper>
  );
};

export default MenuPopper;
