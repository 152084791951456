import React, { useState, Fragment } from 'react';
import { HeaderWrapper } from './scollSpyMenu.style';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/icomoon/user';
import { target } from 'react-icons-kit/icomoon/target';
import { play } from 'react-icons-kit/icomoon/play';
import Collapse from '@material-ui/core/Collapse';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';

const icons = {
  persona: user,
  feature: target,
  demo: play,
};

const MiniMenu = ({ menu }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <a onClick={() => setOpen(!open)}>
        {' '}
        {menu.label} <Icon icon={ic_keyboard_arrow_down} />
      </a>
      <Collapse in={open}>
        {menu.options.map((option, i) => (
          <HeaderWrapper
            style={{ paddingLeft: '20px', color: '#343d48b0' }}
            href={option.path}
            onClick={() => {
              window.open(option.path, '_self');
            }}
          >
            {' '}
            {option.label}
          </HeaderWrapper>
        ))}
      </Collapse>
    </>
  );
};

export default MiniMenu;
