import styled from 'styled-components';

export const OlStyled = styled.ol`
  padding: 0;
  margin: 0;
`;

export const HeaderWrapper = styled.div`
  text-decoration: none;
  -webkit-text-decoration: none;
  margin: 3px 0px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: block;
  border-radius: 50px !important;
  padding: 5px 10px !important;
`;

export const MainUlMenu = styled.li`
  padding: 0;
  list-style: none;
  display: table;
  text-align: center;
  color: black;
  li {
    display: table-cell;
    position: relative;
    padding: 15px 0;
    color: white;
  }
  a {
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
    color: white;
    font-weight: 600;
  }
  a:after {
    color: white;
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    margin-top: 10px;
    position: absolute;
    background: #e8e8e8;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }
  .highlight {
    width: 100%;
    left: 0;
  }
`;

export const HoverDiv = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  max-height: 3rem;
  justify-content: center;
  alignitems: flex-end;
  margin: 20px 0;
  cursor: pointer;
  :hover {
    p:first-child {
      color: #3b4454;
    }
    p:nth-child(2) {
      color: #4c576b;
    }
  }
`;

export const HoverDivBlockRight = styled.a`
  display: flex;
  text-decoration: none;
  color: inherit;
  justify-content: center;
  alignitems: flex-end;
  margin: 30px 0;
  cursor: pointer;
  max-height: 3rem;

  :hover {
    p:first-child {
      color: #3b4454;
    }
    p:nth-child(2) {
      color: #4c576b;
    }
  }
`;

export const MainLi = styled.li``;

export const LiStyled = styled.li`
  padding: 10px 5px;
  font-size: 14px;
  font-weight: 500;
  -webkit-text-decoration: none;
  cursor: pointer;
  color: #343d48;
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const tooltipProps = {
  PopperProps: {
    style: {
      zIndex: 10000,
    },
  },
  TransitionProps: {
    style: {
      zIndex: 10000,
      maxWidth: '100%',
      background: 'white',
      boxShadow: '0px 0px 8px 0px black',
      paddingTop: 0,
      paddingBottom: 0,
      width: '45rem',
      marginTop: '20px',
      borderRadius: '15px',
    },
  },
};
