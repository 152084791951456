import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { ButtonGroup } from '../saas.style';

const TrialSection = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  btnStyle,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...textArea}>
            <Text
              {...description}
              content="Better Process. Better Opportunities."
            />
            <Heading {...title} content="Empower Your Team!" />
            {/* <ButtonGroup className="button_group">
              <Button
                title="GET IN TOUCH"
                {...btnStyle}
                onClick={() => window.open('/contact', '_self')}
              />
            </ButtonGroup> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

TrialSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

TrialSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['80px', '80px', '80px', '80px'],
    pb: ['40px', '40px', '40px', '40px'],
    style: { background: 'linear-gradient(180deg, #4174ff, #2860f9)' },
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: ['32px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'white',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem'],
    color: '#2a313c',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['15px', '15px', '20px', '30px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
    style: {
      background: 'white',
      color: '#2860f9',
    },
  },
};

export default TrialSection;
